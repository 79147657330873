import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import configs from "../../utils/configs";
import { getAppLogo } from "../../utils/get-app-logo";
import { CreateRoomButton } from "./CreateRoomButton";
import { PWAButton } from "./PWAButton";
import { useFavoriteRooms } from "./useFavoriteRooms";
import { usePublicRooms } from "./usePublicRooms";
import styles from "./HomePage.scss";
import { AuthContext } from "../auth/AuthContext";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { MediaGrid } from "../room/MediaGrid";
import { MediaTile } from "../room/MediaTiles";
import { PageContainer } from "../layout/PageContainer";
import { scaledThumbnailUrlFor } from "../../utils/media-url-utils";
import { Column } from "../layout/Column";
import { Button } from "../input/Button";
// import { Container } from "../layout/Container";
import { SocialBar } from "../home/SocialBar";
import { SignInButton } from "./SignInButton";
import maskEmail from "../../utils/mask-email";
import { ReactComponent as HmcLogo } from "../icons/HmcLogo.svg";

import { Container, Row, Col } from "react-bootstrap";
import MainImage from '../../assets/newSkin/mainImg.svg';
import btn1 from '../../assets/newSkin/m-btn1.svg';
import btn2 from '../../assets/newSkin/m-btn2.svg';


export function HomePage() {
  const auth = useContext(AuthContext);
  const intl = useIntl();

  const { results: favoriteRooms } = useFavoriteRooms();
  const { results: publicRooms } = usePublicRooms();

  const sortedFavoriteRooms = Array.from(favoriteRooms).sort((a, b) => b.member_count - a.member_count);
  const sortedPublicRooms = Array.from(publicRooms).sort((a, b) => b.member_count - a.member_count);
  const wrapInBold = chunk => <b>{chunk}</b>;
  const isHmc = configs.feature("show_cloud");
  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      createAndRedirectToNewHub(null, null, true);
    }
  }, []);

  const canCreateRooms = !configs.feature("disable_room_creation") || auth.isAdmin;
  const email = auth.email;
  return (
    <PageContainer className="mainC">
      {auth.isSignedIn ? (
        <Container>
        <Col sm={3} className="main-img"> <a href="/"><img src={MainImage} alt="Main Image" /></a></Col>
        <Col sm={8}></Col>

        <Col sm={6}>
          <Row>
            <Col sm={4}>
            <CreateRoomButton />
            </Col>
          </Row>
        </Col>
        <Col sm={8}></Col>

      </Container>

            // <div className={styles.signInContainer}>
            //   <CreateRoomButton />
            //   <span>
            //     <FormattedMessage
            //       id="header.signed-in-as"
            //       defaultMessage="Signed in as {email}"
            //       values={{ email: maskEmail(email) }}
            //     />
            //   </span>
            //   <a href="#" onClick={auth.signOut} className={styles.mobileSignOut}>
            //     <FormattedMessage id="header.sign-out" defaultMessage="Sign Out" />
            //   </a>
            // </div>
          ) : (
      <Container>
        <Col sm={3} className="main-img"> <a href="/"><img src={MainImage} alt="Main Image" /></a></Col>
        <Col sm={8}></Col>

        <Col sm={6}>
          {/* <Row>
            <Col sm={4}>
              <a  href="/link"><img width="100%" src={btn1} alt="verMundos"/></a>
            </Col>
            <Col sm={4}>
            <a href="/"><img width="100%" src={btn2} alt="btn2"/></a>
            </Col>
          </Row> */}
        </Col>
        <Col sm={8}></Col>

      </Container>)}
    </PageContainer>
   
    // <PageContainer className={styles.homePage}>
    //   <Container>
    //     <div className={styles.hero}>
    //       {auth.isSignedIn ? (
    //         <div className={styles.signInContainer}>
    //           <span>
    //             <FormattedMessage
    //               id="header.signed-in-as"
    //               defaultMessage="Signed in as {email}"
    //               values={{ email: maskEmail(email) }}
    //             />
    //           </span>
    //           <a href="#" onClick={auth.signOut} className={styles.mobileSignOut}>
    //             <FormattedMessage id="header.sign-out" defaultMessage="Sign Out" />
    //           </a>
    //         </div>
    //       ) : (
    //         <SignInButton mobile />
    //       )}
    //       <div className={styles.logoContainer}>
    //         {isHmc ? (
    //           <HmcLogo className="hmc-logo" />
    //         ) : (
    //           <img alt={configs.translation("app-name")} src={getAppLogo()} className="test" />
    //           // <img alt="Prueba de frander" src={getAppLogo()} className="test" />
    //         )}
    //       </div>
    //       <div className={styles.appInfo}>
    //         <div className={styles.appDescription}>{configs.translation("app-description")}</div>
    //         {canCreateRooms && <CreateRoomButton />}
    //         <PWAButton />
    //       </div>
    //       <div className={styles.heroImageContainer}>
    //         <img
    //           alt={intl.formatMessage(
    //             {
    //               id: "home-page.hero-image-alt",
    //               defaultMessage: "Screenshot of {appName}"
    //             },
    //             { appName: configs.translation("app-name") }
    //           )}
    //           src={configs.image("home_background")}
    //         />
    //       </div>
    //     </div>
    //   </Container>
    //   {configs.feature("show_feature_panels") && (
    //     <Container className={classNames(styles.features, styles.colLg, styles.centerLg)}>
    //       <Column padding gap="xl" className={styles.card}>
    //         <img src={configs.image("landing_rooms_thumb")} />
    //         <h3>
    //           <FormattedMessage id="home-page.rooms-title" defaultMessage="Instantly create rooms" />
    //         </h3>
    //         <p>
    //           <FormattedMessage
    //             id="home-page.rooms-blurb"
    //             defaultMessage="Share virtual spaces with your friends, co-workers, and communities. When you create a room with Hubs, you’ll have a private virtual meeting space that you can instantly share <b>- no downloads or VR headset necessary.</b>"
    //             values={{ b: wrapInBold }}
    //           />
    //         </p>
    //       </Column>
    //       <Column padding gap="xl" className={styles.card}>
    //         <img src={configs.image("landing_communicate_thumb")} />
    //         <h3>
    //           <FormattedMessage id="home-page.communicate-title" defaultMessage="Communicate and Collaborate" />
    //         </h3>
    //         <p>
    //           <FormattedMessage
    //             id="home-page.communicate-blurb"
    //             defaultMessage="Choose an avatar to represent you, put on your headphones, and jump right in. Hubs makes it easy to stay connected with voice and text chat to other people in your private room."
    //           />
    //         </p>
    //       </Column>
    //       <Column padding gap="xl" className={styles.card}>
    //         <img src={configs.image("landing_media_thumb")} />
    //         <h3>
    //           <FormattedMessage id="home-page.media-title" defaultMessage="An easier way to share media" />
    //         </h3>
    //         <p>
    //           <FormattedMessage
    //             id="home-page.media-blurb"
    //             defaultMessage="Share content with others in your room by dragging and dropping photos, videos, PDF files, links, and 3D models into your space."
    //           />
    //         </p>
    //       </Column>
    //     </Container>
    //   )}
    //   {sortedPublicRooms.length > 0 && (
    //     <Container className={styles.roomsContainer}>
    //       <h3 className={styles.roomsHeading}>
    //         <FormattedMessage id="home-page.public--rooms" defaultMessage="Public Rooms" />
    //       </h3>
    //       <Column grow padding className={styles.rooms}>
    //         <MediaGrid center>
    //           {sortedPublicRooms.map(room => {
    //             return (
    //               <MediaTile
    //                 key={room.id}
    //                 entry={room}
    //                 processThumbnailUrl={(entry, width, height) =>
    //                   scaledThumbnailUrlFor(entry.images.preview.url, width, height)
    //                 }
    //               />
    //             );
    //           })}
    //         </MediaGrid>
    //       </Column>
    //     </Container>
    //   )}
    //   {sortedFavoriteRooms.length > 0 && (
    //     <Container className={styles.roomsContainer}>
    //       <h3 className={styles.roomsHeading}>
    //         <FormattedMessage id="home-page.favorite-rooms" defaultMessage="Favorite Rooms" />
    //       </h3>
    //       <Column grow padding className={styles.rooms}>
    //         <MediaGrid center>
    //           {sortedFavoriteRooms.map(room => {
    //             return (
    //               <MediaTile
    //                 key={room.id}
    //                 entry={room}
    //                 processThumbnailUrl={(entry, width, height) =>
    //                   scaledThumbnailUrlFor(entry.images.preview.url, width, height)
    //                 }
    //               />
    //             );
    //           })}
    //         </MediaGrid>
    //       </Column>
    //     </Container>
    //   )}
    //   <Container>
    //     <Column center grow>
    //       <Button thin preset="landing" as="a" href="/link">
    //         <FormattedMessage id="home-page.have-code" defaultMessage="Have a room code?" />
    //       </Button>
    //     </Column>
    //   </Container>
    //   {isHmc ? (
    //     <Column center>
    //       <SocialBar />
    //     </Column>
    //   ) : null}
    // </PageContainer>
  );
}
